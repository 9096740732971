import WarningIcon from "@mui/icons-material/Warning"
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import RoundCreateDialog from "components/CreateRoundDialog/CreateRoundDialog"
import Tile from "components/Tile/Tile"
import { dataGridDEde } from "constants/dataGridLocale"
import { Maybe, RoundCreateOrder, SupplierInfo } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { fetchOrderByStatus } from "queries/fetchOrderByStatus"
import { getNextRoundNumber } from "queries/getNextRoundNumber"
import { useEffect, useMemo, useRef, useState } from "react"

// sum up table a
const colsSupplier: GridColDef[] = [
  { field: "supplier", headerName: "Lieferant", width: 150 },
  { field: "count", headerName: "Anzahl", width: 150 },
]

const colsOrders: GridColDef<RoundOrder>[] = [
  { field: "orderID", headerName: "Bestell-ID", width: 125 },
  {
    field: "ewid",
    headerName: "EwID",
    width: 100,
    editable: false,
  },
  {
    field: "bdb",
    headerName: "BDB",
    width: 100,
    renderCell: (params) => {
      return (
        <Link href={`/#/editArticle/${params.row.bdb}`}>
          <Typography variant="body2" color="secondary" component="div">
            {params.row.bdb}
          </Typography>
        </Link>
      )
    },
    sortComparator: (_, __, param1, param2) => {
      return param1.value.localeCompare(param2.value)
    },
    editable: false,
  },
  {
    field: "botname",
    headerName: "Botanischer Name",
    width: 325,
    editable: false,
  },
  {
    field: "count",
    headerName: "Anzahl",
    width: 100,
    editable: false,
  },
  {
    field: "supplier",
    headerName: "Lieferant",
    renderCell: (params) => {
      if (!params.row.supplier) {
        return (
          <Typography
            variant="body2"
            color="error"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <WarningIcon />
            Kein Lieferant
            <WarningIcon />
          </Typography>
        )
      }
      return params.row.supplier.name
    },
    sortComparator: (_, __, param1, param2) => {
      const s1: string = param1.value?.name ?? ""
      const s2: string = param2.value?.name ?? ""
      return s1.localeCompare(s2)
    },
    width: 250,
    editable: false,
  },
  {
    field: "single",
    headerName: "Single",
    width: 100,
    sortable: false,
    renderCell: (params) => {
      // an order is considered single if it consist of one article type only
      //
      return (
        <Typography
          variant="body2"
          color="secondary"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: params.row.isSingle ? "green" : "red",
          }}
        >
          {params.row.isSingle ? "Ja" : "Nein"}
        </Typography>
      )
    },
  },
]

type SupplierInfo2 = {
  supplier: string
  count: number
}

const countTotalSupplier = (data: RoundCreateOrder[]): SupplierInfo2[] => {
  const groupedArray = data.reduce((acc, { supplier, count }) => {
    acc[supplier?.name ?? ""] = (acc[supplier?.name ?? ""] || 0) + count
    return acc
  }, {} as { [supplier: string]: number })
  return Object.entries(groupedArray)
    .map(([supplier, count]) => ({ supplier, count }))
    .sort((a, b) => b.count - a.count)
}

const getTotalNumberOfOrders = (data: RoundCreateOrder[]): number => {
  // count all orders with the same orderID as one
  const d: string[] = []
  for (let i = 0; i < data.length; i++) {
    const o = data[i]
    const index = d.findIndex((s) => s === o.orderID)
    if (index === -1) {
      d.push(o.orderID)
    }
  }
  return d.length
}

const getDiffrentArticles = (data: RoundCreateOrder[]): number => {
  // count all different articles
  const d: string[] = []
  for (let i = 0; i < data.length; i++) {
    const o = data[i]
    const index = d.findIndex((s) => s === o.bdb)
    if (index === -1) {
      d.push(o.bdb)
    }
  }
  return d.length
}

type RoundOrder = {
  bdb: string
  botname: string
  count: number
  ewid: string
  orderID: string
  isSingle: boolean
  supplier?: Maybe<SupplierInfo>
}

const sortOrderData = (data: RoundCreateOrder[]): RoundOrder[] => {
  const singleOrders = getSingleOrders(data)
  const a = data.filter((o) => o.supplier).sort((a, b) => b.count - a.count)
  const b = data
    .filter((o) => !o.supplier)
    .sort((a, b) => b.bdb.localeCompare(a.bdb))
  const l = b.concat(a)
  // add single field to each order

  return l.map((o) => {
    const isSingle = singleOrders.find((s) => s[0] === o.orderID)
    return {
      bdb: o.bdb,
      botname: o.botname,
      count: o.count,
      ewid: o.ewid,
      orderID: o.orderID,
      isSingle: isSingle !== undefined,
      supplier: o.supplier,
    }
  })
}

const getTotalArticleCount = (data: RoundCreateOrder[]): number => {
  // count all articles
  let c = 0
  for (let i = 0; i < data.length; i++) {
    const o = data[i]
    c += o.count
  }
  return c
}

const distincArticleWithoutSupplier = (
  data: RoundCreateOrder[]
): RoundCreateOrder[] => {
  const d: RoundCreateOrder[] = []
  for (let i = 0; i < data.length; i++) {
    const o = data[i]
    const index = d.findIndex((s) => s.bdb === o.bdb)
    if (index === -1 && !o.supplier) {
      d.push(o)
    }
  }
  return d
}

const getSingleOrders = (data: RoundCreateOrder[]): [string, number][] => {
  // get all orders with only one order position
  const d: [string, number][] = []
  for (let i = 0; i < data.length; i++) {
    //count which orderID and make a map
    const o = data[i]
    const index = d.findIndex((s) => s[0] === o.orderID)
    if (index === -1) {
      d.push([o.orderID, 1])
    } else {
      d[index][1]++
    }
  }
  // remove all orders with more than one order position
  const r = d.filter((a) => a[1] === 1)
  return r
}

const numRegex = /^[0-9]+$/

const RoundCreatePage = (): JSX.Element => {
  const [statusValue, setStatusValue] = useState<string>("")
  const statusRef = useRef<HTMLInputElement | null>(null)
  const [orderData, setOrderData] = useState<RoundOrder[]>([])
  const [supplierData, setSupplierData] = useState<SupplierInfo2[]>([])
  const [nextRound, setNextRound] = useState<string | null>(null)
  const [roundCreateDialogIsOpen, setRoundCreateDialogIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleStatusCommit = (e: any) => {
    if (e.key === "Enter") {
      fetchOrders(e.target.value)
    }
  }

  const fetchOrders = async (s: string) => {
    if (s === "" || numRegex.test(s) === false) {
      enqueueSnackbar(`Ungültiger Status ${"" + s}`, {
        variant: "error",
      })
      setSupplierData([])
      setOrderData([])
      return
    }
    setIsLoading(true)
    fetchOrderByStatus(s)
      .then((data) => {
        if (data?.orderByStatus) {
          const supplierInfo = countTotalSupplier(data.orderByStatus)
          setSupplierData(supplierInfo)
          setOrderData(sortOrderData(data.orderByStatus))
          enqueueSnackbar(`Bestellungen mit Status ${s} geladen`, {
            variant: "success",
          })
          setStatusValue(s)
        } else {
          enqueueSnackbar(`Keine Bestellungen mit Status ${"" + s} gefunden`, {
            variant: "error",
          })
          setSupplierData([])
          setOrderData([])
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const articleWithoutSupplier = useMemo(() => {
    return distincArticleWithoutSupplier(orderData)
  }, [orderData])

  useEffect(() => {
    setIsLoading(true)
    getNextRoundNumber().then((r) => {
      if (r !== undefined && r.getNextRoundNumber > 0) {
        setNextRound(r.getNextRoundNumber.toString())
      }
    })
    setIsLoading(false)
    setTimeout(() => {
      if (statusRef.current) {
        statusRef.current.focus()
      }
    }, 200)
  }, [])

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <TextField
                label="Bestellstatus"
                inputRef={statusRef}
                placeholder="Bestellstatus"
                onKeyUp={handleStatusCommit}
                disabled={isLoading}
              />
            </Grid>

            <Grid
              item
              xs={12}
              lg={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tile
                label={"Posten"}
                value={getDiffrentArticles(orderData).toString()}
              />
              <Tile
                label={"Artikel"}
                value={getTotalArticleCount(orderData).toString()}
              />
              <Tile
                label={"Bestellungen"}
                value={getTotalNumberOfOrders(orderData).toString()}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ height: "100%", minWidth: "200px" }}
                  disabled={
                    isLoading ||
                    orderData.length === 0 ||
                    articleWithoutSupplier.length > 0
                  }
                  onClick={() => setRoundCreateDialogIsOpen(true)}
                >
                  Runde {nextRound ?? ""} anlegen
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {articleWithoutSupplier.length > 0 && (
                <Alert severity="error">
                  <AlertTitle>Fehler</AlertTitle>
                  {articleWithoutSupplier.length} Artikel
                  {articleWithoutSupplier.length === 1 ? " hat " : " haben "}
                  keinen Lieferanten: [
                  {articleWithoutSupplier.map((a) => a.bdb).join(", ")}]
                </Alert>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              height: articleWithoutSupplier.length > 0 ? "65vh" : "75vh",
              width: "100%",
            }}
          >
            <DataGridPro
              rows={orderData}
              getRowId={(row: RoundOrder) => row.ewid}
              columns={colsOrders}
              disableRowSelectionOnClick
              localeText={dataGridDEde}
              loading={isLoading}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              height: articleWithoutSupplier.length > 0 ? "65vh" : "75vh",
              width: "100%",
            }}
          >
            <DataGridPro
              rows={supplierData}
              getRowId={(row: SupplierInfo2) => row.supplier}
              columns={colsSupplier}
              disableRowSelectionOnClick
              localeText={dataGridDEde}
              loading={isLoading}
            />
          </Box>
        </Grid>
      </Grid>
      <RoundCreateDialog
        statusOrder={Number(statusValue)}
        open={roundCreateDialogIsOpen}
        nextRoundNumber={nextRound}
        onClose={() => setRoundCreateDialogIsOpen(false)}
      />
    </Container>
  )
}

export default RoundCreatePage
