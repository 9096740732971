import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { RoundOrderArticle, SupplierOrderItem } from "generated/graphql"
import { useMemo, useState } from "react"

export interface RoundOrderSplitItemDialogProps {
  open: boolean
  onClose: () => void
  supplierName: string
  selection: RoundOrderArticle[]
  supplierOrderInfo: SupplierOrderItem[]
  onSplit: (
    splitItems: SupplierOrderItem[],
    selectedStatus: number,
    statusCodesToSumUp: number[]
  ) => void
  isLoading: boolean
}

export const RoundOrderSplitItemDialog = (
  props: RoundOrderSplitItemDialogProps
): JSX.Element => {
  const {
    open,
    onClose,
    selection,
    supplierOrderInfo,
    supplierName,
    onSplit,
    isLoading,
  } = props

  const [selectedSplitItems, setSelectedSplitItems] = useState<number[]>([])
  const [selectedStatus, setSelectedStatus] = useState<number>(0)
  const [allSelectedStatuses, setAllSelectedStatuses] = useState(statusCodes)
  const selectedValues = useMemo(
    () => allSelectedStatuses.filter((v) => v.isSelected),
    [allSelectedStatuses]
  )

  const cols: GridColDef<SupplierOrderItem>[] = [
    { field: "id", headerName: "Lieferant Bestellnr.", width: 150 },
    { field: "orderID", headerName: "Kunde Bestellnr.", width: 150 },
    { field: "ewID", headerName: "EwID", width: 100 },
    { field: "bdb", headerName: "BDB", width: 120 },
    {
      field: "botname",
      headerName: "Artikel",
      renderCell: (params) => {
        return (
          <Box>
            <Typography variant="body1" component="div">
              {params.row.botname}
            </Typography>
            <Typography variant="body2" color="text.secondary" component="div">
              {params.row.sorte} - {params.row.size}
            </Typography>
          </Box>
        )
      },
      width: 300,
    },
    { field: "quantity", headerName: "Anzahl", width: 100 },
  ]

  const filterSelctedData = (
    data: SupplierOrderItem[]
  ): SupplierOrderItem[] => {
    const l: SupplierOrderItem[] = []
    for (let i = 0; i < selection.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (selection[i].articleId === data[j].bdb) {
          l.push(data[j])
        }
      }
    }
    return l
  }

  const handleSelectionModelChange = (
    selectionModel: GridRowSelectionModel
  ) => {
    // WE know that it is a number array since we defined it as such
    setSelectedSplitItems(selectionModel as number[])
  }

  const handleSplitItems = () => {
    const splitItems: SupplierOrderItem[] = []
    for (let i = 0; i < supplierOrderInfo.length; i++) {
      if (selectedSplitItems.includes(supplierOrderInfo[i].id)) {
        splitItems.push(supplierOrderInfo[i])
      }
    }
    onSplit(
      splitItems,
      selectedStatus,
      selectedValues.map((v) => v.code)
    )
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>Artikel-Splitten von {supplierName}</DialogTitle>
      <DialogContent sx={{ height: "70vh", width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ height: "60vh", width: "100%" }}>
              <DataGridPro
                rows={filterSelctedData(supplierOrderInfo)}
                columns={cols}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionModelChange}
                disableRowSelectionOnClick
                localeText={dataGridDEde}
                loading={isLoading}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              id="nextStatusCode"
              getOptionLabel={(option) => option.code + " - " + option.info}
              fullWidth
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              value={statusCodes.find((x) => x.code === selectedStatus) ?? null}
              onChange={(_, newValue) => {
                if (newValue) setSelectedStatus(newValue.code)
              }}
              options={statusCodes}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              id="multiple-status"
              getOptionLabel={(option) => option.code + " - " + option.info}
              fullWidth
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              multiple
              value={selectedValues}
              onChange={(_, newValue) => {
                setAllSelectedStatuses(
                  statusCodes.map((v) => {
                    return {
                      ...v,
                      isSelected: newValue.some((x) => x.code === v.code),
                    }
                  })
                )
              }}
              options={statusCodes}
              renderInput={(params) => (
                <TextField {...params} label="Weitere Status Codes beachten" />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSelectedSplitItems([])
            onClose()
          }}
          fullWidth
          variant="contained"
          color="secondary"
        >
          Abbrechen
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          fullWidth
          onClick={handleSplitItems}
          disabled={selectedSplitItems.length === 0 || selectedStatus === 0}
          color="primary"
        >
          Splitten
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

interface statusCode {
  info: string
  code: number
  isSelected?: boolean
}

// 100 (Neubestellung), 614 (saisonal ausverkauft), 616 (dauerhaft ausverkauft), 617 (Freiland ausverkauft) oder ein Kalenderwochenstatus 7XX.
export const statusCodes: statusCode[] = [
  {
    info: "Neubestellung",
    code: 100,
  },
  {
    info: "Zimmerpflanzen",
    code: 105,
  },
  {
    info: "Hardware",
    code: 106,
  },
  {
    info: "Saisonal ausverkauft",
    code: 614,
  },
  {
    info: "Dauerhaft ausverkauft",
    code: 616,
  },
  {
    info: "Freiland ausverkauft",
    code: 617,
  },
  {
    info: "KW1",
    code: 701,
  },
  {
    info: "KW2",
    code: 702,
  },
  {
    info: "KW3",
    code: 703,
  },
  {
    info: "KW4",
    code: 704,
  },
  {
    info: "KW5",
    code: 705,
  },
  {
    info: "KW6",
    code: 706,
  },
  {
    info: "KW7",
    code: 707,
  },
  {
    info: "KW8",
    code: 708,
  },
  {
    info: "KW9",
    code: 709,
  },
  {
    info: "KW10",
    code: 710,
  },
  {
    info: "KW11",
    code: 711,
  },
  {
    info: "KW12",
    code: 712,
  },
  {
    info: "KW13",
    code: 713,
  },
  {
    info: "KW14",
    code: 714,
  },
  {
    info: "KW15",
    code: 715,
  },
  {
    info: "KW16",
    code: 716,
  },
  {
    info: "KW17",
    code: 717,
  },
  {
    info: "KW18",
    code: 718,
  },
  {
    info: "KW19",
    code: 719,
  },
  {
    info: "KW20",
    code: 720,
  },
  {
    info: "KW21",
    code: 721,
  },
  {
    info: "KW22",
    code: 722,
  },
  {
    info: "KW23",
    code: 723,
  },
  {
    info: "KW24",
    code: 724,
  },
  {
    info: "KW25",
    code: 725,
  },
  {
    info: "KW26",
    code: 726,
  },
  {
    info: "KW27",
    code: 727,
  },
  {
    info: "KW28",
    code: 728,
  },
  {
    info: "KW29",
    code: 729,
  },
  {
    info: "KW30",
    code: 730,
  },
  {
    info: "KW31",
    code: 731,
  },
  {
    info: "KW32",
    code: 732,
  },
  {
    info: "KW33",
    code: 733,
  },
  {
    info: "KW34",
    code: 734,
  },
  {
    info: "KW35",
    code: 735,
  },
  {
    info: "KW36",
    code: 736,
  },
  {
    info: "KW37",
    code: 737,
  },
  {
    info: "KW38",
    code: 738,
  },
  {
    info: "KW39",
    code: 739,
  },
  {
    info: "KW40",
    code: 740,
  },
  {
    info: "KW41",
    code: 741,
  },
  {
    info: "KW42",
    code: 742,
  },
  {
    info: "KW43",
    code: 743,
  },
  {
    info: "KW44",
    code: 744,
  },
  {
    info: "KW45",
    code: 745,
  },
  {
    info: "KW46",
    code: 746,
  },
  {
    info: "KW47",
    code: 747,
  },
  {
    info: "KW48",
    code: 748,
  },
  {
    info: "KW49",
    code: 749,
  },
  {
    info: "KW50",
    code: 750,
  },
  {
    info: "KW51",
    code: 751,
  },
  {
    info: "KW52",
    code: 752,
  },
  {
    info: "Nächstes Jahr",
    code: 800,
  },
]
