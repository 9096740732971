import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectCurrentRound } from "app/roundSlice"
import { qSnack } from "app/snackSlice"
import { dataGridDEde } from "constants/dataGridLocale"
import dayjs from "dayjs"
import {
  OrderListInput,
  RoundOrderItem,
  SendOrderListInput,
} from "generated/graphql"
import { uniq } from "lodash"
import { roundOrderListGet, orderListSend } from "queries/fetchOrderList"
import { orderListPreviewPDF } from "queries/orderListPreviewPDF"
import { supplierRoundNoteSave } from "queries/supplierRoundNote"
import { useCallback, useEffect, useRef, useState } from "react"
import { base64ToBlob } from "utils/base64ToBlob"
import { downloadBlob } from "utils/downloadBlob"

// helper function
//@returns {string} - comma separated supplier names
const getSupplierNames = (ids: number[], ol: RoundOrderItem[]): string => {
  if (ids.length === 0) return ""

  const names: string[] = []
  for (let i = 0; i < ol.length; i++) {
    if (ids.includes(ol[i].supplierID)) {
      names.push(ol[i].supplierName)
    }
  }
  return names.join(", ")
}

export const grpList = [
  "Alle",
  "Pinneberger",
  "Oldenburger",
  "G24",
  "Lager",
  "Sonstige",
]

export const getGrpName = (
  isStorage: boolean,
  g24: boolean,
  flavour: number
): string[] => {
  const grps: string[] = []
  if (isStorage) {
    grps.push("Lager")
  }
  if (g24) {
    grps.push("G24")
  }
  if (flavour === 0) {
    grps.push("Pinneberger")
  }
  if (flavour === 2) {
    grps.push("Oldenburger")
  }
  if (grps.length === 0) {
    grps.push("Sonstige")
  }
  return grps
}

const OrderList = (): JSX.Element => {
  const [orderList, setOrderList] = useState<RoundOrderItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [selection, setSelection] = useState<RoundOrderItem[]>([])
  const [currentGrp, setCurrentGrp] = useState<string>("Alle")

  const handleChangeGrp = (event: any) => {
    setSelection([])
    setCurrentGrp(event.target.value as string)
  }

  const dispatch = useAppDispatch()
  const round = useAppSelector(selectCurrentRound)

  const handleSendItems = (input: SendOrderListInput) => {
    setIsLoading(true)
    orderListSend(input)
      .then((data) => {
        if (data?.sendOrderList) {
          let names = getSupplierNames(
            input.supplier.map((x) => x.supplierID),
            orderList
          )
          names = uniq(names.split(", ")).join(", ")
          //update orderList
          const msg = input.sendEmail
            ? "Emails versendet: " + names
            : "Fax ans sipgate weitergeleitet(es kommt bei Erfolg eine Email: 1-5 min Wartezeit): " +
              names
          dispatch(qSnack({ msg: msg, severity: "success" }))
          roundOrderListGet(input.round).then((data2) => {
            if (data2?.roundOrderList && data2.roundOrderList.items) {
              setOrderList(data2.roundOrderList.items)
            }
          })
        }
      })
      .finally(() => {
        setSelection([])
        setIsLoading(false)
      })
  }

  const handleSendEmailBulk = () => {
    if (!round) return
    const items = selection
      .filter((item) => item.sendEmail)
      .map((item) => {
        let date = item.emailSentAt
        if (date === "") {
          date = item.faxSentAt
        }
        return {
          supplierID: item.supplierID,
          date: date,
        }
      })
    handleSendEmail(round.round, items)
  }

  const handleSendEmail = (round: number, items: OrderListInput[]) => {
    const input: SendOrderListInput = {
      round: round,
      supplier: items,
      sendEmail: true,
      sendFax: false,
    }
    handleSendItems(input)
  }

  const handleSendFaxBulk = () => {
    if (!round) return
    const items = selection
      .filter((item) => item.sendFax)
      .map((item) => {
        let date = item.emailSentAt
        if (date === "") {
          date = item.faxSentAt
        }
        return {
          supplierID: item.supplierID,
          date: date,
        }
      })
    handleSendFax(round.round, items)
  }

  const handleSendFax = (round: number, items: OrderListInput[]) => {
    const input: SendOrderListInput = {
      round: round,
      supplier: items,
      sendEmail: false,
      sendFax: true,
    }
    handleSendItems(input)
  }

  const handleFetchOrderList = useCallback(() => {
    const roundId = round?.round
    if (roundId) {
      //fetch data from server
      setIsLoading(true)
      roundOrderListGet(roundId)
        .then((data) => {
          if (data?.roundOrderList) {
            setOrderList(data.roundOrderList.items)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [setIsLoading, round])

  const handleSelectionModelChange = (selectionModel: any) => {
    //selection model is an array of selected row ids here routeIDs
    // e.g [1,2,3] this comes from the database
    const selectedOrderList = selectionModel.map((id: any) =>
      orderList.find(
        (item) => item.supplierID + item.emailSentAt + item.faxSentAt === id
      )
    )
    setSelection(selectedOrderList)
  }

  const handleSupplierNotes = async (supplierIds: number[], note: string) => {
    setIsLoading(true)
    if (!round) return
    for (let i = 0; i < supplierIds.length; i++) {
      const res = await supplierRoundNoteSave(round.round, supplierIds[i], note)
      if (res === null) return
      if (res === undefined) return
      if (res?.supplierRoundNoteSave !== true) {
        dispatch(
          qSnack({
            msg: "Notiz konnte nicht gespeichert werden",
            severity: "error",
          })
        )
        return
      }
    }
    setIsLoading(false)
    dispatch(
      qSnack({
        msg: "Notiz gespeichert",
        severity: "success",
      })
    )
  }

  const columns: GridColDef<RoundOrderItem>[] = [
    {
      field: "supplierID",
      headerName: "Kurzwahl",
      width: 100,
    },
    {
      field: "supplierName",
      headerName: "Lieferant",
      width: 200,
      editable: false,
    },
    {
      field: "pdfPath",
      headerName: "Bestellliste",
      width: 200,
      editable: false,
      renderCell: (params) => {
        const handleClick = async (_: any) => {
          if (!round) return
          let date = params.row.emailSentAt
          if (date === "") {
            date = params.row.faxSentAt
          }
          orderListPreviewPDF(round?.round, params.row.supplierID, date)
            .then((data) => {
              if (data?.orderListPreviewPDF) {
                const blb = base64ToBlob(
                  data.orderListPreviewPDF.pdfBase64,
                  "application/pdf"
                )
                const now = dayjs().format("DD.MM.YYYY_HH.mm")
                downloadBlob(
                  blb,
                  "R" +
                    round.round.toString() +
                    "_" +
                    params.row.supplierID.toString() +
                    "_" +
                    params.row.supplierName +
                    "_" +
                    now +
                    ".pdf"
                )
              }
            })
            .finally(() => {
              // do nothing
            })
        }
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
            component="div"
            onClick={handleClick}
          >
            PDF Anzeigen
          </Typography>
        )
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 350,
      editable: false,
      renderCell: (params) => {
        const sendEmail = params.row.sendEmail
        const isEmailSent = params.row.emailSentAt !== ""
        const handleClick = () => {
          if (!round) return
          let date = params.row.emailSentAt
          if (date === "") {
            date = params.row.faxSentAt
          }
          handleSendEmail(round.round, [
            { supplierID: params.row.supplierID, date: date },
          ])
        }
        return (
          <>
            {sendEmail && params.value && (
              <>
                {!isEmailSent && <CloseIcon color="error" />}
                {isEmailSent && <DoneIcon color="success" />}
                <Button onClick={handleClick} variant="contained" size="small">
                  {params.value}
                </Button>
              </>
            )}
            {(!sendEmail || !params.value) && <div>Akzeptiert keine Email</div>}
          </>
        )
      },
    },
    {
      field: "emailSentAt",
      headerName: "Email gesendet am",
      valueGetter: (params) => {
        return params.row.emailSentAt
          ? dayjs(params.row.emailSentAt).format("DD.MM.YYYY HH:mm")
          : ""
      },
      width: 150,
    },

    {
      field: "fax",
      headerName: "Fax",
      width: 250,
      editable: false,
      renderCell: (params) => {
        const sendFax = params.row.sendFax
        const isFaxSent = params.row.faxSentAt !== ""
        const handleClick = () => {
          if (!round) return
          handleSendFax(round.round, [
            { supplierID: params.row.supplierID, date: "" },
          ])
        }
        return (
          <>
            {sendFax && (
              <>
                {!isFaxSent && params.value && <CloseIcon color="error" />}
                {isFaxSent && <DoneIcon color="success" />}
                <Button onClick={handleClick} variant="contained" size="small">
                  {params.value}
                </Button>
              </>
            )}
            {(!sendFax || !params.value) && <div>Akzeptiert kein Fax</div>}
          </>
        )
      },
    },
    {
      field: "faxSentAt",
      headerName: "Fax gesendet am",
      valueGetter: (params) => {
        return params.row.faxSentAt
          ? dayjs(params.row.faxSentAt).format("DD.MM.YYYY HH:mm")
          : ""
      },
      width: 150,
    },

    {
      field: "contacted",
      valueGetter: (params) => {
        return params.row.emailSentAt !== "" || params.row.faxSentAt !== ""
      },
      headerName: "Kontaktiert",
      width: 150,
      editable: false,
      renderCell: (params) => {
        const value =
          params.row.faxSentAt !== "" || params.row.emailSentAt !== ""
        return (
          <Typography
            variant="body2"
            sx={{
              color: value ? "success.main" : "error.main",
            }}
          >
            {value ? "Ja" : "Nein"}
          </Typography>
        )
      },
      sortComparator: (params1, params2, _, __) => {
        if (params1 === params2) return 0
        return params1 ? 1 : -1
      },
    },

    {
      field: "phone",
      headerName: "Telefon",
      width: 500,
      editable: false,
    },
    {
      field: "g24",
      headerName: "G24",
      editable: false,
    },
    {
      field: "flavour",
      headerName: "Flavour",
      editable: false,
    },
    {
      field: "inStorage",
      headerName: "Lager",
      editable: false,
    },
  ]

  useEffect(() => {
    if (round !== undefined && round !== null) {
      handleFetchOrderList()
    }
  }, [round, handleFetchOrderList])

  const noteRef = useRef<HTMLInputElement>(null)
  const handleSupplierNoteEnter = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      const selectedSupplierIds = selection.map((item) => item.supplierID)
      if (selectedSupplierIds.length > 0) {
        handleSupplierNotes(selectedSupplierIds, noteRef.current?.value || "")
      }
    }
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <LoadingButton
                variant="contained"
                component="label"
                fullWidth
                loading={isLoading}
                sx={{ height: "100%" }}
                disabled={
                  selection.filter((item) => item.sendEmail).length === 0
                }
                onClick={handleSendEmailBulk}
              >
                SENDE MAIL (
                {
                  selection.filter((item: RoundOrderItem) => item.sendEmail)
                    .length
                }
                )
              </LoadingButton>
            </Grid>
            <Grid item xs={2}>
              <LoadingButton
                variant="contained"
                component="label"
                fullWidth
                sx={{ height: "100%" }}
                disabled={selection.filter((item) => item.sendFax).length === 0}
                loading={isLoading}
                onClick={handleSendFaxBulk}
              >
                SENDE FAX ({selection.filter((item) => item.sendFax).length})
              </LoadingButton>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-group">
                  Gruppieren
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-group"
                  id="demo-simple-select-group"
                  value={currentGrp}
                  label="Gruppieren"
                  onChange={handleChangeGrp}
                >
                  {grpList.map((grp) => (
                    <MenuItem key={grp} value={grp}>
                      {grp}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            placeholder="Information den Lieferanten-Bestellung zuweisen mit Enter senden"
            fullWidth
            inputRef={noteRef}
            onKeyUp={handleSupplierNoteEnter}
            disabled={selection.length === 0}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: "71vh", width: "100%" }}>
            <DataGridPro
              rows={orderList
                .sort((a, b) => a.supplierName.localeCompare(b.supplierName))
                .filter((item) => {
                  if (currentGrp === "Alle") return true
                  // item includes in current group
                  // an item can be in more than one group
                  const grps = getGrpName(
                    item.inStorage,
                    item.g24,
                    item.flavour
                  )
                  return grps.includes(currentGrp)
                })}
              columns={columns}
              getRowId={(item) =>
                item.supplierID + item.emailSentAt + item.faxSentAt
              }
              localeText={dataGridDEde}
              sortingOrder={["desc", "asc"]}
              loading={isLoading}
              checkboxSelection
              onRowSelectionModelChange={handleSelectionModelChange}
              rowSelectionModel={selection.map(
                (item) => item.supplierID + item.emailSentAt + item.faxSentAt
              )}
              disableRowSelectionOnClick
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default OrderList
